import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import GlobalStyle from "./utils/globalStyle";
import Validate from "pages/admin/validate";

const AppLayout = lazy(() => import("./components/AppLayout"));
const Home = lazy(() => import("./pages/admin/home"));
const AdminLogin = lazy(() => import("./pages/admin/login"));
const UserClient = lazy(() => import("./pages/client/user-client"));
const UserAdmin = lazy(() => import("./pages/admin/user-admin"));
// const AdminProfile = lazy(() => import("./pages/admin/profile"));
const NotFound = lazy(() => import("./pages/not-found"));
const UserGroup = lazy(() => import("./pages/user-group"));
const UserGroupDetail = lazy(() => import("./pages/user-group/detail"));

function App() {
  return (
    <Suspense>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route element={<AppLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/clients" element={<UserClient />} />
            <Route path="/admins" element={<UserAdmin />} />
            {/* <Route path="/profile" element={<AdminProfile />} /> */}
            <Route path="/user-groups" element={<UserGroup />} />
            <Route path="/user-group/:groupId" element={<UserGroupDetail />} />
          </Route>
          <Route path="admin">
            <Route path="login" element={<AdminLogin />} />
            <Route path="validate" element={<Validate />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;

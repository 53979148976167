export const theme = {
  colors: {
    swf: {
      grey: {
        0: "#ffffff",
        50: "#F3F4FA",
        100: "#f5f7fb",
        200: "#B1BCDA",
        300: "#b1bcdb",
        400: "#435179",
        500: "#eceff7",
        600: "#747e99",
        700: "#303030",
        800: "#1f1f1f",
      },
      yellow: {
        300: "#ffff61",
      },
      purple: {
        300: "#b92790",
        400: "#b8278f",
        500: "#5719a0",
        700: "#4d2f82",
      },
    },
  },

  font: {
    family: {
      lato: "'Lato', sans-serif",
    },
  },
};

import { createGlobalStyle } from "styled-components";

import loginBanner from "../static/images/login-banner.svg";
import { theme } from "./styles";

const GlobalStyle = createGlobalStyle`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  input {
    font-family: ${theme.font.family.lato};
  }

  /* Login Banner */

  .login__banner {
    background-image: url(${loginBanner});
  }

  /* Sidebar Collapse */

  .ant-tooltip {
    font-family: ${theme.font.family.lato};

    .ant-tooltip-inner {
      background: ${theme.colors.swf.grey[0]};
      color: ${theme.colors.swf.grey[200]};
    }

    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: ${theme.colors.swf.grey[0]};
    }
  }

  .ant-menu-submenu {
    &.ant-menu-submenu-popup:not(.ant-menu-submenu-hidden) {
      left: 90px !important;
    }
  }

  .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark, .ant-menu.ant-menu-dark .ant-menu-sub {
    background: ${theme.colors.swf.grey[0]};
  }

  .ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item>a, .ant-menu-dark .ant-menu-item>span>a {
    color: ${theme.colors.swf.grey[200]};
  }

  .ant-menu-submenu-popup {
    .ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
      &.ant-menu-item-selected {
        background-color: ${theme.colors.swf.grey[50]} !important;
        color: ${theme.colors.swf.purple[300]};
      }

      &:hover {
        color: ${theme.colors.swf.purple[300]} !important;
      }
    }
  }

  .ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover {
    color: ${theme.colors.swf.purple[300]};
    font-weight: bold;
    transition: all .1s;
  }

  /* Popup Modal */

  .ant-modal-root {
    color: ${theme.colors.swf.grey[400]};
    font-family: ${theme.font.family.lato};

    .ant-modal-content {
      border-radius: 8px;
    }

    .ant-modal-title {
      color: ${theme.colors.swf.grey[400]};
    }

    .ant-modal-header {
      border-bottom: 1px solid transparent;
      border-radius: 8px;
    }

    .ant-modal-close {
      color: ${theme.colors.swf.grey[400]};
    }

    .ant-modal-close:focus, .ant-modal-close:hover {
      color: ${theme.colors.swf.purple[300]};
    }

    .ant-modal-body {
      padding: 0 24px;
    }

    .ant-modal-footer {
      border-top: 1px solid transparent;
      display: flex;
      gap: 15px;
      justify-content: flex-end;
      padding: 24px;
    }

    .ant-btn {
      align-items: center;
      border-radius: 6px;
      display: flex;
      font-family: ${theme.font.family.lato};
      height: 41px;
      justify-content: center;
      padding: 12px 0;
      width: 50%;
    }

    .ant-btn-primary {
      background: ${theme.colors.swf.purple[300]};
      background-size: 100% 200%;
      background-image: linear-gradient(to top, ${theme.colors.swf.purple[700]} 50%, ${theme.colors.swf.purple[300]} 50%);
      border-color: ${theme.colors.swf.purple[300]};
      transition: background-position .2s;

      &[disabled] {
        background-image: linear-gradient(to top, #d9d9d9 50%, #d9d9d9 50%);
        border-color: #d9d9d9;
        color: #FFF;

        &:hover {
          border-color: #d9d9d9;
        }
      }

      &:hover {
        background-position: 0 100%;
        border-color: ${theme.colors.swf.purple[700]};
      }
    }

    .ant-btn-default {
      border-color: ${theme.colors.swf.purple[300]};
      background-size: 100% 200%;
      background-image: linear-gradient(to top, ${theme.colors.swf.purple[300]} 50%, ${theme.colors.swf.grey[0]} 50%);
      color: ${theme.colors.swf.purple[300]};

      &[disabled] {
        background-image: linear-gradient(to top, #d9d9d9 50%, #d9d9d9 50%);
        border-color: #d9d9d9;
        color: #FFF;

        &:hover {
          border-color: #d9d9d9;
        }
      }

      &:hover {
        background-position: 0 100%;
        color: ${theme.colors.swf.grey[0]};
      }
    }
  }

  .login__btn {
    background-size: 100% 200%;
    background-image: linear-gradient(to top, ${theme.colors.swf.purple[700]} 50%, ${theme.colors.swf.purple[300]} 50%);
    transition: background-position .2s;

    &:hover {
      background-position: 0 100%;
      border-color: ${theme.colors.swf.purple[700]};
    }
  }

  .ant-btn {
    display: flex;
    align-items: center;
  }

  .ant-table-tbody > tr >td {
    vertical-align: top;
  }
`;

export default GlobalStyle;

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const enum ValidateState {
  DEFAULT = "DEFAULT",
  INVALID = "INVALID",
  INVALID_NO_ENTITIES = "INVALID_NO_ENTITIES",
  VALIDATING = "VALIDATING",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  NON_SIRCLO_EMAIL = "NON_SIRCLO_EMAIL",
}

const enum StatusCode {
  SUCCESS = "200",
  NOT_FOUND = "404",
  NOT_AUTHORIZED = "401",
  SERVER_ERROR = "500",
}

function Validate() {
  const location = useLocation();
  const navigate = useNavigate();
  const [validatingState, setValidatingState] = useState<ValidateState>(
    ValidateState.DEFAULT
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const statusCode = searchParams.get("status_code");

    if (statusCode === StatusCode.SUCCESS) {
      const token = searchParams.get("token");
      const userId = searchParams.get("user_id");
      const role = searchParams.get("role");

      const data = {
        userId,
        token,
        ttl: Date.now() + 3600 * 1000 * 24, // store the TTL (time to live) in a day, token will expiry in one day
        role,
      };
      localStorage.setItem("__auth__", JSON.stringify(data));
      navigate("/");
    }

    if (statusCode === StatusCode.NOT_FOUND) {
      setValidatingState(ValidateState.USER_NOT_FOUND);
    }

    if (statusCode === StatusCode.NOT_AUTHORIZED) {
      setValidatingState(ValidateState.NON_SIRCLO_EMAIL);
    }

    if (statusCode === StatusCode.SERVER_ERROR) {
      setValidatingState(ValidateState.INVALID);
    }
  }, [location.search, navigate]);

  const components: Partial<Record<keyof typeof ValidateState, JSX.Element>> = {
    [ValidateState.DEFAULT]: <p>Validating..</p>,
    [ValidateState.VALIDATING]: (
      <p className="text-center text-lg">Redirecting..</p>
    ),
    [ValidateState.NON_SIRCLO_EMAIL]: (
      <p className="text-center text-lg">
        Oops! 🙈 Looks like we're in the wrong neighborhood! Only email
        addresses under SIRCLO organization can hop on the Google Oauth train!
        🚂💨 Let's get you back on track! 🛤️
      </p>
    ),
    [ValidateState.USER_NOT_FOUND]: (
      <p className="text-center text-lg">
        Uh oh! 😅 It looks like your email isn't on our guest list! No worries
        though, we've got your back! 🎉 Feel free to reach out to your awesome
        Osiris Representative for a helping hand! They're the pros at making
        things right! 🌟📧
      </p>
    ),
    [ValidateState.INVALID]: (
      <p className="text-center text-lg">
        Ooopsss, there is something wrong with us. Don't worry it is on us not
        you! 🛠️📧
      </p>
    ),
    [ValidateState.INVALID_NO_ENTITIES]: (
      <p>
        We Got You! you can't access any dashboard right now, fear not! 🌟 Your
        fantastic osiris members representative can be your data knight in
        shining armor.📧✨
      </p>
    ),
  };

  function onNavigateToLogin() {
    navigate("/admin/login");
  }

  return (
    <div className="flex flex-col mt-10 max-w-lg mx-auto">
      <div>{components[validatingState] || <p>loading...</p>}</div>
      <div className="mt-3">
        <button
          data-testid="button-login-el"
          type="submit"
          className="login__btn inline-block px-7 py-3 bg-swf-purple-300 text-white font-medium text-sm leading-snug font-lato rounded shadow-md 
                      focus:bg-swf-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-swf-purple-700 active:shadow-lg w-full"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          onClick={onNavigateToLogin}
        >
          <span>Back to Login Page</span>
        </button>
      </div>
    </div>
  );
}

export default Validate;
